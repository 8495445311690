.file-input {
    width: 240px;
    height: 45px;

    input[type='file'] {
        position: absolute;
        opacity: 0;
        padding: 0.8rem;
    }

    .input-label {
        background: #bfdbf3;
        color: #0071ce;
        padding: 12px 20px;
        border-radius: 5px;
    }

    .input-label-change {
        background: rgba(0, 113, 206, 0.25);
        padding: 12px 20px;
        border-radius: 5px;
        font-weight: 500;
        color: #0071ce;
    }
}