table .main-table-head {
  background: #D9EAF8 !important;
  }
  
  table .main-table-head tr th span {
    color: #FF7F30 !important;
  }

  .special-content table tbody tr td {
    color: #0071CE;
    font-weight: bold;
  }

  .total-transactions {
    border: 1px solid #d9eaf8;
    padding: 6px;
    border-radius: 4px;
    margin-top: -25px;
    margin-left: 15px;
  }

  .view-transactions-btn {
    margin-top: -17px !important;
    margin-bottom: 30px !important
  }

  .save-btn {
    margin-top: -18px !important;
  }

  @media screen and (max-width: 1280px) {
    .view-transactions-btn {
      margin-top: 10px !important;
      margin-bottom: 0 !important;
    }
  }

  @media screen and (max-width: 1112px) {
    .total-transactions {
      margin-top: 10px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 1096px) {
    .total-transactions {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .total-transactions {
      margin-top: -45px !important;
      margin-left: 10px;
    }

    .view-transactions-btn {
      margin-top: -45px !important;
    }
  }

  @media screen and (max-width: 970px) {
    .view-transactions-btn {
      margin-top: 10px !important;
    }
  }

  @media screen and (max-width: 811px) {
    .total-transactions {
      margin-top: 10px !important;
      margin-bottom: 0 !important;
      margin-left: 15px !important;
    }

    .view-transactions-btn {
      margin-top: 10px !important;
    }
  }

  @media screen and (max-width: 800px) {
    .total-transactions {
      margin-left: 0 !important;
    }
  }
  @-moz-document url-prefix() {
    @media screen and (max-width: 541px) {
      .total-transactions {
        margin-top: -5px !important;
        margin-left: 10px !important;
      }

      .save-btn {
        margin-top: 20px !important;
        margin-bottom: 10px !important;
      }

      .view-transactions-btn {
        margin-top: 0 !important;
      }
    }

  }

  @media screen and (max-width: 486px) {
    .total-transactions {
      margin-top: -15px !important;
      margin-left: 10px !important;
    }

    .save-btn {
      margin-top: 10px !important;
    }

    .view-transactions-btn {
      margin-top: 10px !important;
    }
  }

    @media screen and (max-width: 486px) {
      .total-transactions {
        margin-top: -16px !important;
        margin-left: 10px !important;
      }

      .save-btn {
        margin-top: 10px !important;
      }

      .view-transactions-btn {
        margin-top: 10px !important;
      }
    }

  @-moz-document url-prefix() {
    @media screen and (max-width: 453px) {
      .total-transactions {
        margin-top: 10px !important;
        margin-left: 10px !important;
      }

      .save-btn {
        margin-top: -13px !important;
      }

      .view-transactions-btn {
        margin-top: 10px !important;
      }
    }
  }
  @media screen and (max-width: 398px) {
    .total-transactions {
      margin-top: 10px !important;
    }

    .save-btn {
      margin-top: -14px !important;
    }
  }
  @-moz-document url-prefix() {
  @media screen and (max-width: 361px) {
    .total-transactions {
      margin-top: 0 !important;
    }

    .save-btn {
      margin-top: 13px !important;
    }
  }
}

@media screen and (max-width: 335px) {      
  .save-btn {
    margin-top: 13px !important;
    margin-bottom: 0 !important;
  }
}